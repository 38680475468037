import React from 'react'
import join from 'lodash/join'
import Layout from 'screens/shared/Layout'
import Head from 'components/modules/Head'
import { Box, Flex } from 'components/primitives'
import Text from 'components/core/Text'
import Badge from 'components/core/Badge'
import { graphql } from 'gatsby'
import { container } from 'components/core/Layout'

const ReviewItem = ({ review, ...props }) => {
  return (
    <Box mb={5} {...props}>
      <Text fontSize={3}>“{review.content}”</Text>
      <Flex alignItems='center'>
        <Text
          fontSize={3}
          fontWeight='bold'
          fontFamily='monospace'
          as='span'
          mr='auto'
        >
          — {review.user}
        </Text>
        {review.product_names.length - 2 > 0 ? (
          <Badge>
            {review.product_names[0]} 외 {review.product_names.length - 1}종
            구매
          </Badge>
        ) : (
          <Badge>{join(review.product_names, ', ')} 구매</Badge>
        )}
      </Flex>
    </Box>
  )
}

export default ({
  data: {
    allAirtable: { nodes }
  }
}) => {
  return (
    <Layout {...container} maxWidth={450} pt={5}>
      <Head title='후기' />
      {nodes.map(node => (
        <ReviewItem key={node.data.uid} review={node.data} />
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "후기" } }
      sort: { fields: data___sort_order, order: ASC }
    ) {
      nodes {
        data {
          id
          content
          user
          product_names
          sort_order
          submitted_at
        }
      }
    }
  }
`
